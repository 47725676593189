.page-for-collaborators {
    .top-navbar {
        background-color: #000424;
    }
}

.collab-signup-section {
    color: #FFF;

    .registration-step-item {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        * {
            transition: all .5s ease;
        }

        .step-number {
            position: relative;
            align-self: stretch;
            flex-shrink: 0;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 48px;
                font-size: 1.5rem;
                font-weight: 700;
                color: var(--bs-primary);
                background-color: rgba(var(--bs-primary-rgb), .2);
                border-radius: .5rem;
            }

            .step-number-line {
                display: none;
            }
        }

        .step-content {
            opacity: .2;
            h3 {
                font-size: 1.25rem;
                margin-bottom: .75rem;
            }
            
            p {
                margin: 0;
            }
        }

        &:not(:last-child) {
            .step-number {
                &::before {
                    content: '';
                    position: absolute;
                    top: 60px;
                    left: 20px;
                    width: 10px;
                    height: 10px;
                    background-color: rgba(var(--bs-secondary-rgb), .2);
                    border-radius: 50%;
                }

                .step-number-line {
                    content: '';
                    position: absolute;
                    top: 70px;
                    bottom: 12px;
                    left: 24px;
                    display: block;
                    width: 2px;
                    background-color: rgba(var(--bs-secondary-rgb), .2);
                    border-radius: 2px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
    
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 100%;
                        left: 0;
                        right: 0;
                        opacity: 0;
                        background-color: var(--bs-primary);
                        transition: bottom .5s ease-in-out, opacity linear;
                    }
                }
            }

            .step-content {
                padding-bottom: 2rem;
            }
        }

        &.active {
            .step-number {
                span {
                    color: #FFF;
                    background-color: var(--bs-primary);
                }

                &::before {
                    background-color: var(--bs-primary);
                }

                .step-number-line {
                    &::after {
                        bottom: 0;
                        opacity: 1;
                    }
                }
            }

            .step-content {
                opacity: 1;
            }
        }
    }

    @media (max-width: 767px) {
        .registration-step-item {
            .step-number {
                span {
                    font-size: 1rem;
                    width: 24px;
                    height: 24px;
                }
            }

            .step-content {
                h3 {
                    font-size: 1rem;
                }

                p {
                    font-size: .875rem;
                }
            }
            
            &:not(:last-child) {
                .step-number {
                    &::before {
                        top: 32px;
                        left: 8px;
                        width: 8px;
                        height: 8px;
                    }
    
                    .step-number-line {
                        top: 40px;
                        left: 11px;
                    }
                }
    
                .step-content {
                    padding-bottom: 2rem;
                }
            }
        }
    }
}