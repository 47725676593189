/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/
.site {
	position: relative;
	min-height: 100vh;
	position: relative;
	overflow: hidden;
}

.page-layout {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    min-height: 100vh;
    transition: all .15s ease;
}

.page-content {
    background-color: #FFF !important;
    color: #000 !important;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.top-navbar {
    padding: 1.5rem 0;
    transition: all .15s ease;

    &.scroll {
        padding: .5rem 0;
        background-color: #000424;
        backdrop-filter: blur(20px);
        box-shadow: var(--bs-box-shadow);
    }

    @media (max-width: 767px) {
        padding-top: .75rem;
        padding-bottom: .75rem;
    }

    @media (max-width: 991px) {
        background-color: rgba(35, 119, 252, .24);
        backdrop-filter: blur(20px);
        box-shadow: var(--bs-box-shadow);
    }
    
    .navbar-brand {
        font-weight: 600;

        img {
            width: 160px;
            height: 48px;

            @media (max-width: 767px) {
                width: 128px;
                height: 40px;
            }
        }
    }

    .navbar-nav {
        .dropdown {
            .dropdown-menu {
                z-index: 1030;
                opacity: 0;
                border-color: var(--bs-gray-300);
                border-radius: 0.75rem;
                background: rgba(255, 255, 255, 0.30);
                backdrop-filter: blur(10px);

                &.show {
                    opacity: 1;
                    animation: fadeIn 150ms ease;
                }

                .dropdown-item {
                    color: #FFF;
                    padding: .5rem var(--bs-gutter-x, 0.75rem);
                    transition: background-color .15s ease;

                    &:hover {
                        background-color: rgba(255, 255, 255, .2);
                    }

                    &:focus {
                        background-color: var(--bs-primary);
                    }
                }

                .dropdown-divider {
                    border-color: var(--bs-gray-200);
                }

                @media (min-width: 992px) {
                    border: none;
                    margin-top: 1rem;
                    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                }

            }
        }

        .nav-item {
            .nav-link {
                padding: .75rem 1rem;
                color: #FFF;
                font-weight: 500;

                @media (max-width: 767px) {
                    padding-left: .25rem;
                    padding-right: .25rem;
                }
            }
        }

        .btn {
            padding: .75rem 1rem;
        }
    }

    .navbar-user-dropdown {
        color: #000;

        @media (min-width: 992px) {
            width: 160px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        .dropdown-toggle {
            width: 36px;
            height: 36px;
            padding: 0 !important;
            border-radius: 50%;
            overflow: hidden;

            &::after {
                content: '' !important;
                display: none !important;
            }

            img {
                width: 36px;
                height: 36px;
            }
        }

        .dropdown-menu {
            width: 280px;
            border: none;
            margin-top: 1rem;

            @media (max-width: 992px) {
                position: fixed;
                top: 44px;
                right: .5rem;
            }
        }

        .dropdown-item {
            color: #000;

            &:focus {
                color: #FFF;
            }
        }

        .navbar-user {
            color: #000;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-left: var(--bs-gutter-x, 0.75rem);
            padding-right: var(--bs-gutter-x, 0.75rem);

            .navbar-user-avatar {
                flex: 0 0 36px;
                width: 36px;
                height: 36px;
                padding: 0;
                border-radius: 50%;
                overflow: hidden;
                margin-right: .5rem;

                img {
                    width: 36px;
                    height: 36px;
                }
            }

            .navbar-user-info {
                flex: 1 1;
                width: calc(100% - 36px - .5rem);
                text-align: left;

                .navbar-user-fullname {
                    font-weight: 500;
                    line-height: 1.3;
                    margin-bottom: 0;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                
                .navbar-user-username {
                    color: var(--bs-gray-600);
                    font-size: .75rem;
                    line-height: 1.3;
                    margin-bottom: 0;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .navbar-toggler {
        border-color: transparent !important;
        padding: .35rem .25rem;
        box-shadow: none !important;
    }

    &.navbar-inapp {
        .not-inapp {
            display: none;
        }
    }

    .btn {
        line-height: 24px;
        vertical-align: middle;
    }
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.site-footer {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    color: #FFF;
    background-color: #000424;

    .footer-brand {
        margin-bottom: 1rem;
    }

    h5 {
        @media (max-width: 767px) {
            margin-top: 1.5rem;
        }
    }

    .footer-links {
        list-style: none;
        padding-inline-start: 0;
        margin-bottom: 0;
        
        li {
            margin-top: 1.25rem;

            a {
                color: rgba(255, 255, 255, .5);
                text-decoration: none;
            }
        }
    }

    .footer-policy-links {
        text-align: center;

        a {
            font-size: .75rem;
        }

        .col-6 {
            &:not(:last-child) {
                border-right: 1px solid #FFF;
                margin-bottom: 1rem;
            }
            &:nth-child(2n) {
                @media (max-width: 767px) {
                    border-right: none;   
                }
            }
        }
    }
}

.footer-social-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: .5rem;
    margin-top: 1.25rem;
}

.site-copyright {
	text-align: center;
}

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
.site-content {
    flex-grow: 1;
}