@import 'assets/scss/variables.scss';

.content-page-section {
    // color: #000;
    background-color: var(--bs-dark);

    h2 {
        margin-top: 2rem;
        margin-bottom: .75rem;
    }

    h3 {
        &:not(:first-child) {
            margin-top: 2rem;
        }

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }

    a {
        color: var(--bs-link-color);
        font-weight: 600;
        
        &:not(.btn) {
            text-decoration: underline;
        }
    }

    img {
        max-width: 100% !important;
        height: auto !important;
    }

    .img-guide {
        text-align: center;
        margin: 1.5rem auto;

        img {
            border-radius: .875rem;

            @media (min-width: 768px) {
                max-width: 434px !important;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}