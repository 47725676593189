@import 'assets/scss/variables.scss';

.dot-animated-wrapper {
    position: relative;
    display: inline-block;
    vertical-align: -2px;
    width: 10px;
    height: 1rem;

    .dot-animated {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10px;
        height: 100%;
        overflow: visible;

        .dot {
            position: absolute;
            top: calc(50% - 5px);
            left: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }

        .dot-anim {
            position: absolute;
            top: calc(50% - 5px);
            left: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            opacity: .5;
            animation: zoomIn 2s infinite ease;
        }

        @keyframes zoomIn {
            0%      { transform: scale(0); }
            100%    { transform: scale(2); }
        }
    }
}

.home-header {
    min-height: 100vh;
    padding-top: 14rem;
    padding-bottom: 10rem;
    color: #FFF;
    display: flex;
    align-items: center;

    // &::before {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     z-index: 1;
    //     bottom: 100px;
    //     left: -200px;
    //     width: 400px;
    //     height: 400px;
    //     border-radius: 50%;
    //     background: linear-gradient(37deg, rgba(0, 255, 255, 0.60) 14.29%, rgba(85, 0, 255, 0.60) 85.71%);
    //     filter: blur(132px);
    // }

    // &::after {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     z-index: -1;
    //     top: -250px;
    //     right: -300px;
    //     width: 640px;
    //     height: 640px;
    //     border-radius: 50%;
    //     background: linear-gradient(37deg, rgba(0, 255, 255, 0.60) 14.29%, rgba(85, 0, 255, 0.60) 85.71%);
    //     filter: blur(132px);
    // }

    &::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        height: 140px;
        background: linear-gradient(180deg, #000424 50%, rgba(1, 5, 39, 0.00));
    }

    .section-bg {
        background-position: top center;
    }

    .sec-header {
        .sec-big-title {
            font-size: 3rem;
        }

        .sec-title {
            font-size: 2.5rem;
            font-weight: 400;
        }

        .sec-header-badge {
            position: relative;
            font-size: 1rem;
            padding: 0.5rem 1rem;
        }
    }

    @media (max-width: 991px) {
        padding-top: 8rem;
        padding-bottom: 6rem;

        .sec-header {
            .sec-big-title {
                font-size: 2.25rem;
            }
            .sec-title {
                font-size: 18px;
                margin-bottom: 1rem;
            }
    
            .sec-description {
                font-size: 1rem;
            }
        }
    }

    @media (max-width: 767px) {
        .section-bg {
            padding-bottom: calc(5rem + 96px);
        }

        .countdown-timer-wrapper {
            margin-top: 320px;
        }
    }
}

.about-section {
    &::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        height: 200px;
        background: linear-gradient(180deg, #000424 50%, rgba(0, 4, 36, 0.00));
    }

    .about-item {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: 100%;
        padding: 2rem;
        border-radius: 1rem;
        backdrop-filter: blur(50px);

        // background-color: rgba($color: var(--bs-primary-rgb), $alpha: .4);
        background: linear-gradient(-35deg, rgba(0, 77, 199, .5), rgba(0, 107, 196, .5));
        border: 1px solid rgba($color: var(--bs-info-rgb), $alpha: .1);
        box-shadow: 0px -3px 40px 0px rgba($color: var(--bs-info-rgb), $alpha: .1) inset;

        // background-color: rgba(255, 255, 255, 0.25);
        // border: 1px solid rgba(255, 255, 255, 0.3);
        // box-shadow: 0px -3px 60px 0px rgba(0, 0, 0, 0.1) inset;
    }
}

.future-section {
    .sec-header {
        margin-bottom: 2rem;

        .sec-header-badge {
            position: relative;
            font-size: 1rem;
            padding: 0.5rem 1rem;
            margin-bottom: .5rem;
        }
    }

    .future-content-wrapper {
        padding: 3rem 0 6rem;
        // background-color: #071b45;
        background: linear-gradient(to bottom, rgba($color: var(--bs-primary-rgb), $alpha: 0.1), rgba($color: var(--bs-primary-rgb), $alpha: 0));
        // background-color: rgba($color: var(--bs-primary-rgb), $alpha: 0.1);
        border-top: 1px solid #2a7acc;

        .sec-header {
            margin-bottom: 6rem;
        }
    }

    .future-item {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 1rem;
        text-align: left;

        &::before {
            content: '';
            position: absolute;
            z-index: 2;
            top: -50px;
            left: 20px;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background-color: var(--bs-info);
            mix-blend-mode: soft-light;
            opacity: .5;
            transition: opacity .35s ease;
            box-shadow: 2px 2px 32px rgba(0, 0, 0, .5);
        }

        &:hover {
            &::before {
                opacity: 1;
            }
        }

        .future-item-number {
            position: relative;
            z-index: 1;
            font-size: 6rem;
            font-weight: 700;
            line-height: 1;
        }

        .future-content {
            position: relative;
            z-index: 1;
            margin-top: .45rem;

            p {
                margin-bottom: 1.5rem;
            }
        }
    }
}

.stake-pool-tabs {
    display: inline-block;
    padding: .25rem;

    .nav-pills {
        .nav-link {
            font-weight: 600;
        }
    }
}

.features-section {
    // &::before {
    //     content: '';
    //     position: absolute;
    //     z-index: 0;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     height: 140px;
    //     background: linear-gradient(180deg, #000424 50%, rgba(0, 4, 36, 0.00));
    // }

    &::after {
        content: '';
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 140px;
        background: linear-gradient(0deg, #000424 50%, rgba(0, 4, 36, 0.00));
    }

    .container-xl {
        position: relative;
        z-index: 1;
    }

    .feature-item {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1.5rem;

        .why-item-icon {
            margin-top: .125rem;
        }

        p {
            margin-bottom: 1.5rem;
        }
    }
}

.join-section {
    .section-bg {
        opacity: .1;
    }
}

.partner-section {
    color: #FFF;

    .sec-header {
        margin-bottom: 4rem;
    }

    .partner-img-slider {
        .swiper-wrapper {
            align-items: center;
        }
    }

    .marketpartner-wrapper {
        padding: 1.25rem;
        padding-top: 2rem;
        border-radius: 1rem;
        border-bottom: 10px solid var(--bs-primary);
        color: var(--bs-body-color);
        background-color: #FFF;
        margin-top: 4rem;
        margin-bottom: -12rem;

        .sec-header {
            .sec-title {
                font-size: 2.25rem;
                margin-bottom: 1rem;
            }
        }
    }
}

.news-section {
    padding-top: 12rem;
    z-index: 2;

    .article {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        overflow: hidden;
        background-color: #FFF;
        filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, .05));
        transition: background-color .15s ease;

        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    
        .article-image {
            position: relative;
            z-index: 1;
            height: 200px;
            overflow: hidden;
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform .25s ease;
            }
        }
    
        .article-info {
            padding: 1.5rem 1rem;
    
            .article-title {
                font-size: 1.5rem;
                margin-bottom: .75rem;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                align-self: stretch;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .article-description {
                margin-bottom: 0;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                align-self: stretch;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.social-section {
    background-color: rgba(0, 0, 0, .5);
    background: linear-gradient(to bottom, rgba(var(--bs-primary-rgb), 0.1), rgba(var(--bs-primary-rgb), 0));

    .navbar-social-link {
        img {
            width: 72px;
            height: 72px;
            object-fit: contain;
        }
    }

    .navbar-social-page {
        .nav-link {
            font-weight: 600;
            color: var(--bs-body-color);
        }
    }

    @media (max-width: 767px) {
        .navbar-social-link {
            img {
                width: 64px;
                height: 64px;
            }
        }
    }
}

.cta-section { 
}